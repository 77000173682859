<template>
    <section
        class="o-flexible-module"
        :class="{ 'o-flexible-module--flush': settings.spacing == 'flush' }">
        <div class="grid-container" v-if="settings.showComponentTitle">
            <div class="grid-x grid-margin-x">
                <div class="small-12 cell">
                    <div class="utl-heading-beta">
                        Solutions
                    </div>
                </div>
            </div>
        </div>

        <div class="c-solutions">
            <div
                class="c-solutions__media"
                :class="{ 'has-overlay': settings.backgroundOverlay.showBackgroundOverlay == true }">
                <img :src="image.sourceUrl" :srcset="image.srcSet" :sizes="image.sizes" :alt="image.altText" :title="image.title" />
            </div>

            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="small-12 medium-6 cell">
                        <h2 class="c-solutions__title" data-aos="fade-up">
                            {{ title }}
                        </h2>
                    </div>

                    <div class="small-12 medium-5 medium-offset-1 cell" v-if="content">
                        <div class="c-solutions__content s-wp-cms" v-html="content"></div>
                    </div>
                </div>
            </div>

            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="small-12 cell">
                        <div
                            class="c-solutions__pods"
                            :class="{ 'show-all': showAll }">
                            <template v-for="(solution, index) in solutions" :key="index">
                                <NuxtLink :to="$convertUrl(solution.link.url)" class="c-solutions__pod" v-if="solution.link">
                                    <div v-if="solution.backgroundImage" class="c-solutions__pod-bg">
                                        <img
                                            :src="solution.backgroundImage.sourceUrl" 
                                            :srcset="solution.backgroundImage.srcSet" 
                                            :sizes="solution.backgroundImage.sizes" 
                                            :alt="solution.backgroundImage.altText" 
                                            :title="solution.backgroundImage.title"
                                        />
                                    </div>
                                    <img
                                        v-if="solution.icon && !solution.icon.mimeType.includes('svg')"
                                        class="c-solutions__pod-image"
                                        :src="solution.icon.sourceUrl" />

                                    <inline-svg
                                        v-if="solution.icon && solution.icon.mimeType.includes('svg')"
                                        class="c-solutions__pod-image"
                                        :src="solution.icon.sourceUrl">
                                    </inline-svg>

                                    <div class="c-solutions__pod-title">
                                        {{ solution.title }}
                                    </div>

                                    <div class="c-solutions__pod-content s-wp-cms" v-html="solution.content" v-if="solution.content"></div>
                                </NuxtLink>

                                <div class="c-solutions__pod" v-else>
                                    <img
                                        v-if="solution.icon && !solution.icon.mimeType.includes('svg')"
                                        class="c-solutions__pod-image"
                                        :src="solution.icon.sourceUrl" />

                                    <inline-svg
                                        v-if="solution.icon && solution.icon.mimeType.includes('svg')"
                                        class="c-solutions__pod-image"
                                        :src="solution.icon.sourceUrl">
                                    </inline-svg>

                                    <div class="c-solutions__pod-title">
                                        {{ solution.title }}
                                    </div>

                                    <div class="c-solutions__pod-content s-wp-cms" v-html="solution.content" v-if="solution.content"></div>
                                </div>
                            </template>
                        </div>

                        <div class="c-solutions__button-wrapper" v-if="solutions.length > 4">
                            <button class="c-solutions__all" type="button" v-on:click="showAll = !showAll">
                                <span v-if="showAll">Show less</span>
                                <span v-else>
                                    <template v-if="buttonText">
                                        {{ buttonText }}
                                    </template>

                                    <template v-else>
                                        All Solutions
                                    </template>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
    .c-solutions__media.has-overlay:before {
        background-color: rgba(#000, v-bind(settings.backgroundOverlay.backgroundOverlayStrength));
    }
</style>

<script setup>
    import InlineSvg from 'vue-inline-svg';

    const showAll = ref(false);

    const props = defineProps({
        title: {
            type: String,
            required: true
        },
        content: {
            type: String,
            required: false
        },
        buttonText: {
            type: String,
            required: false
        },
        solutions: {
            type: Object,
            required: true
        },
        image: {
            type: Object,
            required: true
        },
        settings: {
            backgroundOverlay: {
                showBackgroundOverlay: {
                    type: Boolean,
                    required: true,
                },
                backgroundOverlayStrength: {
                    type: Number,
                    required: true
                }
            },
            showComponentTitle: {
                type: Boolean,
                required: true,
            },
            spacing: {
                type: String,
                required: true
            }
        }
    });
</script>
